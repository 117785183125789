import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {useDispatch, useSelector} from "react-redux";
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {fetchMedia, fetchPageData} from "../../api/redux/media";
import InnerBannerV2 from "../../components/InnerBannerV2";
import MediaList from "../../components/media/MediaList";
import GetInTouch from "../../components/GetInTouch";
import BrandKit from "../../components/media/BrandKit";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.GETMEDIA
        let api_url_page_data = apiEndPoints.MEDIA
        dispath(fetchMedia([api_url]))
        dispath(fetchPageData([api_url_page_data]))
    }, [])

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    let getPost = useSelector(state => state.media)
    let option = getPost?.posts?.total?.filter;
    const [click, setClick] = useState('All');
    const [selected, setSelected] = useState('')


    //Refactor Data

    const banner = getPost?.page_data?.page_data?.banner;
    const footerTop = getPost?.page_data?.page_data?.footer_top;
    const brandkit = getPost?.page_data?.page_data?.brandkit;
    const medias = getPost?.posts?.list;

    const meta = getPost?.page_data?.page_data?.meta;


    //Filter projects according to its category
    let filteredMedia = Array.isArray(medias) ? click == 'All' ? medias : medias.filter(element => element?.type?.[0]?.name === click) : '';
    const [clear, setClear] = useState(1);


    const [offset, setOffset] = useState(0)

    useEffect(() => {
        if (window.innerWidth > 767) {
            setOffset(document.querySelector('.container')?.offsetLeft)
        }
        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                setOffset(document.querySelector('.container')?.offsetLeft)
            }
        })
    }, [])

    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : 'Contact'} | Infratech Consultants`}</title>
                <meta name="description" content={meta && meta.meta_desc ? meta.meta_desc
                    : 'Infratech Consultants specializes in designing & implementing hardware, software and other cutting-edge technological ' +
                    'solutions in the public infrastructure domain. We offer products and efficient customized solutions ' +
                    'through our wide network of partners, spread across the globe. With a vast experience in the public sector,' +
                    ' we ensure seamless implementation and execution of a diverse range of projects.'}/>

            </Helmet>


            <InnerBannerV2 img={banner?.image} title={banner?.subtitle} text={banner?.title}
                           mobile_img={banner?.mobile_image}/>
            <BrandKit offset={offset} data={brandkit}/>

            <MediaList data={filteredMedia} option={option} setClick={setClick} setClear={setClear} selected={selected}
                       setSelected={setSelected} rawList={medias}/>
            <GetInTouch data={footerTop}/>

        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
