import React, {useEffect} from 'react';
import styled from "styled-components";

import {useParams} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {useDispatch, useSelector} from "react-redux";
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {fetchMediaDetail} from "../../api/redux/media";
import Details from "../../components/media/Details";
import GetInTouch from "../../components/GetInTouch";
import {Loading} from "../../components/Loading";

const MyComponent = () => {

    const dispatch = useDispatch()
    const getData = useSelector(store => store.media)
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.MEDIA_DETAIL
        dispatch(fetchMediaDetail([api_url + `/${slug}`]))
    }, [])


    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    let meta =getData?.detail?.[0]?.meta;

    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : getData?.detail?.[0]?.post_title} | Infratech Consultants`}</title>
                <meta name="description" content={meta && meta.meta_desc ? meta.meta_desc
                    : 'Infratech Consultants specializes in designing & implementing hardware, software and other cutting-edge technological ' +
                    'solutions in the public infrastructure domain. We offer products and efficient customized solutions ' +
                    'through our wide network of partners, spread across the globe. With a vast experience in the public sector,' +
                    ' we ensure seamless implementation and execution of a diverse range of projects.'}/>
            </Helmet>

            <StyledComponent>
                {/*{getData?.detailLoading && <Loading/>}*/}
                <Details data={getData}/>
                <GetInTouch data={getData?.detail?.[0]?.footer_top}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
