import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchPageData, fetchPosts} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import InnerBannerV2 from "../../components/InnerBannerV2";
import Projects from "../../components/Projects/Projects";
import GetInTouch from "../../components/GetInTouch";
import {Loading} from "../../components/Loading";

const MyComponent = () => {

    const dispath = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.GETPROJECT
        let api_url_page_data = apiEndPoints.PROJECT
        dispath(fetchPosts([api_url]))
        dispath(fetchPageData([api_url_page_data]))
    }, [])


    let getPost = useSelector(state => state.posts)
    let option = getPost?.posts?.total?.filter;

    const [click, setClick] = useState('All');
    const [selected, setSelected] = useState('')



    useEffect(() => {
        window.scroll(0, 0)
    }, [getPost])

    //Refactor Data

    const banner = getPost?.page_data?.page_data?.banner;
    const footerTop = getPost?.page_data?.page_data?.footer_top;
    const projects = getPost?.posts?.list;

    const meta = getPost?.page_data?.page_data?.meta;


    //Filter projects according to its category
    let filteredProjects = Array.isArray(projects) ? click == 'All' ? projects : projects.filter(element => element?.type?.[0]?.name === click) : '';
    const [clear, setClear] = useState(1);

    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : 'Projects'} | Infratech Consultants`}</title>
                <meta name="description" content={meta && meta.meta_desc ? meta.meta_desc
                    : 'Infratech Consultants specializes in designing & implementing hardware, software and other cutting-edge technological ' +
                    'solutions in the public infrastructure domain. We offer products and efficient customized solutions ' +
                    'through our wide network of partners, spread across the globe. With a vast experience in the public sector,' +
                    ' we ensure seamless implementation and execution of a diverse range of projects.'}/>
            </Helmet>


            <StyledComponent>
                {/*{getPost?.loading && <Loading/>}*/}
                <InnerBannerV2 img={banner?.image} title={banner?.subtitle} text={banner?.title}
                               mobile_img={banner?.mobile_image}/>
                <Projects data={filteredProjects} option={option} setClick={setClick} setClear={setClear}
                          selected={selected}
                          setSelected={setSelected} rawList={projects}/>
                <GetInTouch data={footerTop}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
